// stylelint-disable property-disallowed-list, declaration-no-important

//
// Border
//

.border         { border: $border-width solid var(--border-color, $border-color) !important; }
.border-top     { border-top: $border-width solid var(--border-color, $border-color) !important; }
.border-right   { border-right: $border-width solid var(--border-color, $border-color) !important; }
.border-bottom  { border-bottom: $border-width solid var(--border-color, $border-color) !important; }
.border-left    { border-left: $border-width solid var(--border-color, $border-color) !important; }

.border-0        { border: 0 !important; }
.border-top-0    { border-top: 0 !important; }
.border-right-0  { border-right: 0 !important; }
.border-bottom-0 { border-bottom: 0 !important; }
.border-left-0   { border-left: 0 !important; }

@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: var(--border-#{$color}, #{$value}) !important;
  }
}


@each $size, $value in $borders {
  .border-width-#{$size} {
    border-width: var(--border-width-#{$size}, #{$value}) !important;
  }
}

@each $size, $value in $borders {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      @if not ("#{$infix}" == "") {
        @if not ("#{$size}" == "") {
          .border#{$infix}-width-#{$size} {
            border-width: var(--border-width-#{$size}, #{$value}) !important;
          }
        }
      }
    }
  }
}
