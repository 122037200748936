@import "mixins/widget";

.widget{

  &:root{
    --widget-title-bg-color: transparent;
    --widget-title-padding-top: var(--widget-title-padding-y);
    --widget-title-padding-bottom: var(--widget-title-padding-y);
    --widget-title-padding-left: var(--widget-title-padding-x);
    --widget-title-padding-right: var(--widget-title-padding-x);
  }


  //border: var(--widget-border, none);
  //background-color: var(--widget-background-color, transparent);
  min-width: 0;
  position: var(--widget-position, relative);
  display: var(--widget-display, flex);
  flex-direction: var(--widget-flex-direction, column);
  word-wrap: var(--widget-word-wrap, break-word);
  background-clip: var(--widget-background-clipborder-box);
  margin: var(--widget-margin, 0 0 $spacer 0);

  .widget-title{
    padding: var(--widget-title-padding, 10px 0);
    margin-bottom: var(--widget-title-margin-bottom, 10px);
    font-size: var(--widget-title-font-size, inherit);
  }

  &.widget-color:not(.top){
    padding-top: var(--widget-padding-top, 0px);
    border-width: var(--widget-border-width, 0);
    border-color: var(--widget-border-color, $border-color);
    border-style: var(--widget-border-style, solid);

    .widget-title{
      border-radius: var(--widget-title-radius, 0);
      border-bottom: var(--widget-title-border-bottom, 1px solid $border-color);
    }
  }

  &.widget-rounded{
    border-radius: var(--widget-rounded, 5px);
  }


  &:not(.widget-small):not(.widget-box):not(.widget-color){
    >.widget-title{
      text-transform: uppercase;
    }
  }

  &.widget-box{
    >.widget-title{
      display: block;
      border: none;
      border-bottom: 3px solid $yellow;
      background-color: transparent;
      margin-bottom: 15px;
      padding: 0;
      color: #3d3d3d;
      box-shadow:  0px 11px 9px -10px rgba(0,0,0,0.2) !important;
      span{
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid #e5e5e5;
        border-bottom: none;
        padding: 0.375rem 1rem;
        margin-bottom: 0px;
        line-height: 2.5;
        border-radius: 0.25rem;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2) !important;
        float: left;
      }
    }
  }


  .widget-body{
    flex: 1 1 auto;
    padding: 0;
    .card, .card-footer, .card-header{
      border-radius: 0;
    }
    .card-footer{
      border: none;
      background-color: transparent;
    }
  }
}