.btn-white {
  border-color: #e3ebf6;
}

a:focus,
button:focus
{
  outline: none !important;
}


@each $color, $value in $theme-colors {
  .badge-soft-#{$color} {
    @include badge-variant($value);
  }
}

img, svg.logo{
  max-width: 100%;
  max-height: 100%;
}



@each $breakpoint in map-keys($grid-breakpoints) {
  $next: breakpoint-next($breakpoint, $grid-breakpoints);
  $infix: breakpoint-infix($next, $grid-breakpoints);
  @include media-breakpoint-down($breakpoint) {

    .ha#{$infix}, .img-h#{$infix}-auto{
      height: auto !important;
      max-height: 100% !important;
    }

    .wa#{$infix},.img-w#{$infix}-auto{
      width: auto !important;
      max-width: 100% !important;
    }

    .hf#{$infix},.img-h#{$infix}-full{
      height: 100% !important;
    }

    .wf#{$infix},.img-w#{$infix}-full{
      width: 100% !important;
    }

    .hi#{$infix},.img-h#{$infix}-inherit{
      height: inherit !important;
    }

    .wi#{$infix},.img-w#{$infix}-inherit{
      width: inherit !important;
    }

    @include media-breakpoint-only($breakpoint) {

      .hao#{$infix}{
        height: auto !important;
        max-height: 100% !important;
      }

      .wao#{$infix}{
        width: auto !important;
        max-width: 100% !important;
      }

      .hfo#{$infix}{
        height: 100% !important;
      }

      .wfo#{$infix}{
        width: 100% !important;
      }

      .hio#{$infix}{
        height: inherit !important;
      }

      .wio#{$infix}{
        width: inherit !important;
      }
    }
  }
}

.ellipsis {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  //word-break: break-all;
}


.ellipsis-word-break {
  word-break: break-all;
}

@each $name in map-keys($ellipsis-sizes) {
  $size: map-get($ellipsis-sizes, $name);
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    @include media-breakpoint-down($breakpoint) {
      .ellipsis#{$infix}-#{$name} {
        -webkit-line-clamp: #{$size};
      }
    }
  }
}
