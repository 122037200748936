//@warn GRID building;
@import '~bootstrap/scss/grid';

@if $enable-grid-classes {
  //@include make-extend-grid-columns();
  @include make-columns();
  @include make-rows();
}

//make-grid-columns(10)
.grid{
  margin-left: -1px;
  margin-top: -1px;
}
