@import '~bootstrap/scss/functions';
@import '~@xem/look-styles/src/variables';
@import '~@xem/look-styles/src/luke/mixins';

.click-on-select{
  border: none;
  outline: none;
  width: 100%;
  padding: 0;
  margin: 0;
}

input{
  &.click-on-select{
    font-size: map-get($h-sizes, "h3");
  }
}
