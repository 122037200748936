@each $rounded, $value in $rounds {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      //@if not ($infix == "") {
      .rounded#{$infix}-#{$rounded} {
        border-radius: $value !important;
      }

      .rounded#{$infix}-top-#{$rounded} {
        border-top-left-radius: $value !important;
        border-top-right-radius: $value !important;
      }

      .rounded#{$infix}-bottom-#{$rounded} {
        border-bottom-left-radius: $value !important;
        border-bottom-right-radius: $value !important;
      }

      .rounded#{$infix}-top-left-#{$rounded} {
        border-top-left-radius: $value !important;
      }

      .rounded#{$infix}-top-right-#{$rounded} {
        border-top-right-radius: $value !important;
      }

      .rounded#{$infix}-bottom-left-#{$rounded} {
        border-bottom-left-radius: $value !important;
      }

      .rounded#{$infix}-bottom-right-#{$rounded} {
        border-bottom-right-radius: $value !important;
      }
      //}
    }
  }
}
