// Typography

// [converter] $parent hack
@mixin text-variant($parent, $value) {
  #{$parent} {
    font-size: $value !important;
  }
}


// [converter] $parent hack
@mixin text-line-height-variant($parent, $value) {
  #{$parent} {
    //@warn $parent;
    line-height: $value !important;
  }
}
