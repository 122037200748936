.qrcode-grid{
  &.row-small{
    margin: -0.2818rem;
    &> [class*="col"], &> [class*="col-"]{
      padding: 0.2817rem !important;
      margin: 0 !important;
    }
  }
}

.Qr-item{
  box-shadow: none !important;
  -moz-box-shadow: none !important;
}

.widget{
  &.widget-qrcode{
    &.widget-color{
      .widget-title{
        font-size: 14px;
        font-weight: 500;
        padding: 0 15px;
        margin: 0;
        border-bottom: none;
      }

      .widget-body{
        padding: 10px 15px 0;
        margin-bottom: 0;
      }
    }
  }
}


:root{
  --qrcode-grid: 10px;
  --colorful-grid: 10px;

  @media screen and (max-width: 390px) {
    --qrcode-grid: 6px;
  }

  @media (min-width: 391px) and (max-width: 393px) {
    --qrcode-grid: 6.3px;
  }

  @media (min-width: 394px) and (max-width: 414px) {
    --qrcode-grid: 8.3px;
  }

  @media (min-width: 415px) and (max-width: 428px) {
    --qrcode-grid: 9.5px;
  }

  --qrcode-grid-margin: calc(var(--qrcode-grid) * -1);
  --colorful-grid-margin: calc(var(--colorful-grid) * -1);
}


[data-modal-popup]{
  &.qrcode{

    .tool-color{
      & [data-modal-overlay]{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    @media screen and (max-width: 374px ), (min-width: 376px) and (max-width: 450px) {
      .qrcode-grid {
        margin: 0 var(--qrcode-grid-margin) !important;
        > [class*="col"], > [class*="col-"] {
          padding: 0 var(--qrcode-grid) 15px !important;
        }
      }
    }
  }
}

