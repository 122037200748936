@import './mixins/background-variant';
.btn {
  +.btn{
    margin-left: 5px;
  }

  &.btn-reply {
    border: 1px solid #ced4da;
    border-radius: 0.5rem;
    min-width: 100px;
    padding: 0.275rem 0.5rem
  }

  &.btn-google {
    span.fa {
      color: theme-color("danger");
    }

    &:hover {
      span.fa {
        color: #fff;
      }
    }
  }
  &.btn-none{
    padding: 0px;
    margin: 0px;
  }

  &.btn-box, &.btn-checkbox{
    border: 1px solid #c4cdd5;
    color: #212b35;
    @include extend-button-variant(#f9fafb)
  }

  .btn-outline-box{
    border: 1px solid #c4cdd5;
  }


  &.btn-checked{
    background: -webkit-gradient(linear,left top,left bottom,from(#eee),to(#fff));
    background: linear-gradient(to bottom,#eee,#fff);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) inset;
  }
  &.btn-checkbox{
    &::after, &::before {
      position: absolute;
      top: 0.15625rem;
      left: 0.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      content: "";
    }
    &::after {
      background: no-repeat 50% / 50% 50%;
    }
    &::before {
      pointer-events: none;
      background-color: #fff;
      border: #adb5bd solid 1px;
    }
    &::before {
      border-radius: 0.25rem;
    }

    padding-left: 25px !important;
  }
}

.btn-group-vertical{
  &> .btn{
    & +.btn{
      margin-left: 0;
    }
  }
}

.input-group-prepend{
  > .btn{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.input-group-append{
  > .btn{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}


.nav-link{
  a{
    width: 100%;
  }
}

//.custom-radio+.custom-radio{
//  margin-left: 10px;
//}

.product-info{
  button.buy:hover{
    color: theme-color('danger');
    background-color: color('white');
  }
}


.custom-checkbox-box{
  padding-left: 0;
  margin-right: 10px !important;
  .custom-control-input{
    opacity: 0;
  }

  .custom-control-label{
    display: inline-block;
    font-weight: $btn-font-weight;
    color: $body-color;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: $btn-border-width solid transparent;

    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
    @include transition($btn-transition);

    @include hover {
      color: $body-color;
      text-decoration: none;
    }
    border-radius: 0;

    &::before, &::after{
      opacity: 0;
      position: absolute;
    }
  }

  &.custom-checkbox-outline{
    .custom-control-label {
      border-color: $border-color;
    }

    input:checked ~ .custom-control-label {
      border-color: theme-color('danger');
      color: theme-color('danger');
    }
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: transparent !important;
}
