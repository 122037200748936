@import '~nprogress/nprogress.css';
@import '~bootstrap/scss/functions';
@import '~react-focal/src/focal.scss';
@import '~@xem/look-styles/src/variables';
@import '~@xem/look-styles/src/luke/mixins';
@import '~@xem/look-styles/src/bootstrap';
@import '~@xem/look-styles/src/luke/index';
@import '~@xem/look-styles/src/styles';
@import '~@xem/look-styles/src/luke/widget';
@import '~@gqlapp/base/colorful/css/styles.scss';
@import '~@xem/home-client-react/styles';
@import "social/social-icon.scss";
@import "clickToSelect";
@import "mini-xmind";
@import "bottomsheet";
@import "youtube";
@import "panel";
@import "main";
@import "modal";
@import "phone";
@import "qrcode";
@import "Tiptap";


.social-icon {
  font-size: 30px;
  line-height: 30px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.flex-col{
  flex-direction: column;
}

#nprogress {
  z-index: 9999999999999;
  .bar {
    background: var(--bar-color, red);
  }
}
