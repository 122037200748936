:root{
  --react-colorful-saturation-width: 200px;
  --react-colorful-saturation-height: 200px;

  --react-colorful-width: 200px;
  --react-colorful-height: 24px;

  --react-colorful-vertical-width: 24px;
  --react-colorful-vertical-height: 200px;


  --react-colorful-control-size: 24px;
  --react-colorful-pointer-size: 36px;
  --react-colorful-pointer-translate: translate(-6px, -6px);
  --react-colorful-vertical-pointer-translate: translate(-6px, -18px);
}

.react-colorful {
  position: relative;
  display: flex;
  flex-direction: column;
  user-select: none;
  cursor: default;
}

.react-colorful__saturation {
  width: var(--react-colorful-saturation-width);
  height: var(--react-colorful-saturation-height);
  position: relative;
  flex-grow: 1;
  border-color: transparent; /* Fixes https://github.com/omgovich/react-colorful/issues/139 */
  border-bottom: 0;
  border-radius: 6px;

  .react-colorful__saturation_inner{
    border-radius: 6px;
    background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0)),
    linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.react-colorful__pointer-fill,
.react-colorful__alpha-gradient,
.react-colorful--pointer-inner,
.react-colorful__gradient {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  border-radius: inherit;
}

/* Improve elements rendering on light backgrounds */
.react-colorful__gradient,
.react-colorful__saturation {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.react-colorful__hue,
.react-colorful__alpha {
  position: relative;
  border-radius: 6px;
  width: var(--react-colorful-width);
  height: var(--react-colorful-height);
  .react-colorful__pointer{
    transform: var(--react-colorful-pointer-translate);
  }
}



.react-colorful__gradient_slide{
  position: relative;
  border-radius: 20px;
  margin: var(--react-colorful--gradient-margin, 15px 0);
  width: var(--react-colorful--gradient-width, calc(100%));
  height: var(--react-colorful--gradient-height, 24px);

  .react-colorful__gradient_wrapper{
    height: 100%;
    margin: 0 12px;
    position: relative;
  }

  .react-colorful__interactive{
    position: relative;
  }

  .react-colorful__pointer{
    width: 20px;
    height: 20px;
    padding: 0;
    border: none;
    transform: translate(-50%, 2px) !important;
    z-index: 1;

    .react-colorful--pointer-inner{
      border: 2px solid #fff;
    }

    &.active{
      z-index: 10;
      width: 30px;
      height: 30px;
      transform: translate(-50%, -4px) !important;
    }
  }
}

.react-colorful__hue {
  background: linear-gradient(
    to right,
    #f00 0%,
    #ff0 17%,
    #0f0 33%,
    #0ff 50%,
    #00f 67%,
    #f0f 83%,
    #f00 100%
  );
}



.react-colorful__interactive {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  outline: none;
  /* Don't trigger the default scrolling behavior when the event is originating from this element */
  touch-action: none;
}

.react-colorful__pointer {
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  width: var(--react-colorful-pointer-size);
  height: var(--react-colorful-pointer-size);
  transform: translate(-50%, -50%);
  //background-color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.react-colorful__interactive:focus .react-colorful__pointer {
  transform: translate(-50%, -50%) scale(1.05);
}

/* Chessboard-like pattern for alpha related elements */
.react-colorful__alpha,
.react-colorful__alpha-pointer {
  background-color: #fff;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill-opacity=".05"><rect x="8" width="8" height="8"/><rect y="8" width="8" height="8"/></svg>');
}

.react-colorful__saturation_bg{
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill-opacity=".05"><rect x="8" width="8" height="8"/><rect y="8" width="8" height="8"/></svg>');
  position: relative;
}
/* Campaign the saturation pointer over the hue one */
.react-colorful__saturation-pointer {
  z-index: 3;
}

/* Campaign the hue pointer over the alpha one */
.react-colorful__hue-pointer {
  z-index: 2;
}


.react-colorful--vertical{
  display: flex;
  justify-content: center!important;
  flex-direction: row;
  .react-colorful__saturation_wrapper{
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .react-colorful__hue{
    background: linear-gradient(
                    to bottom,
                    #f00 0%,
                    #ff0 17%,
                    #0f0 33%,
                    #0ff 50%,
                    #00f 67%,
                    #f0f 83%,
                    #f00 100%
    );
  }


  .react-colorful__saturation{
    width: var(--react-colorful-saturation-width);
    height: var(--react-colorful-saturation-height);
  }

  .react-colorful__hue,
  .react-colorful__alpha{
    width: var(--react-colorful-vertical-width);
    height: var(--react-colorful-vertical-height);
  }

  .react-colorful__saturation,
  .react-colorful__hue,
  .react-colorful__alpha{
    .react-colorful__pointer{
      width: var(--react-colorful-pointer-size);
      height: var(--react-colorful-pointer-size);
    }
  }

  .react-colorful__hue,
  .react-colorful__alpha{
    .react-colorful__pointer{
      transform: var(--react-colorful-vertical-pointer-translate);
    }
  }
}

