.table, .MuiTable-root {
  thead{
    th{
      border-width: $border-width;
    }
  }

  vertical-align: middle;
  //& thead th {
  //  font-size: .8125rem;
  //  font-weight: 600;
  //
  //  letter-spacing: .08em;
  //  text-transform: uppercase;
  //
  //  color: #95aac9;
  //  border-bottom-width: 1px;
  //  background-color: #f9fbfd;
  //}

  & [data-sort]::after {
    margin-left: .25rem;

    content: url('data:image/svg+xml;utf8,<svg width=\'6\' height=\'10\' viewBox=\'0 0 6 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z\' fill=\'%2395AAC9\'/></svg>');
  }

  &.table-list{

    td, tr{
      position: relative;
    }

    th,td{
      &.auto{width:1px;white-space:nowrap;}
    }

    .toolbars{
      position: absolute;
      display: none;
      height: calc(100%);
      top: 0;
      right: 0;
      z-index: 1;
    }

    tr:hover{
      .toolbars{
        display: -webkit-box !important;
        display: flex !important;
        -webkit-box-align: center !important;
        align-items: center !important;
        z-index: 2;
        background-color: $table-hover-bg;
        ~ .value{
          opacity: 0;
        }
      }

    }
  }
}


.table-sm {
  font-size: .8125rem;

  & thead th {
    font-size: .625rem;
  }
}

.table-nowrap th,
.table-nowrap td {
  white-space: nowrap;
}
