@font-face {font-family: "social-icon";
  src: url('social-icon.eot?t=1723861785990'); /* IE9*/
  src: url('social-icon.eot?t=1723861785990#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("social-icon.woff2?t=1723861785990") format("woff2"),
  url("social-icon.woff?t=1723861785990") format("woff"),
  url('social-icon.ttf?t=1723861785990') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('social-icon.svg?t=1723861785990#social-icon') format('svg'); /* iOS 4.1- */
}

.social-icon {
  font-family: 'social-icon' !important;
  font-style:normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}