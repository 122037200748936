:root {
  @each $color, $value in $theme-colors {
    --theme-#{$color}: #{$value};
  }
}


:root {

  @each $color, $value in $theme-colors {
    --bg-#{$color}: var(--theme-#{$color}, #{$value});

    .bg-#{$color}{
      background-color: var(--bg-#{$color}, #{$value}) !important;
    }
  }

  @each $color, $value in $theme-colors {
    @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        //@warn #{$infix}
        @if not ("#{$infix}" == "") {
          .bg#{$infix}-#{$color}{
            background-color: var(--bg-#{$color}, #{$value}) !important;
          }
        }
      }
    }
  }
}


:root {

  @each $color, $value in $theme-colors {
    --text-#{$color}: var(--theme-#{$color}, #{$value});

    .text-#{$color}{
      color: var(--text-#{$color}, #{$value}) !important;
    }
  }

  @each $color, $value in $theme-colors {
    @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        //@warn #{$infix}
        @if not ("#{$infix}" == "") {
          .text#{$infix}-#{$color}{
            color: var(--text-#{$color}, #{$value}) !important;
          }
        }
      }
    }
  }
}


:root {

  @each $shadow, $value in $shadows {
    --shadow-#{$shadow}: #{$value};

    @if ("#{$shadow}" == "normal") {
      .shadow{
        box-shadow: var(--shadow-#{$shadow}, #{$value}) !important;
      }
    }

    @if not ("#{$shadow}" == "normal") {
      .shadow-#{$shadow}{
        box-shadow: var(--shadow-#{$shadow}, #{$value}) !important;
      }
    }
  }

  @each $shadow, $value in $shadows {
    @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @if not ("#{$infix}" == "") {
          @if not ("#{$shadow}" == "normal") {
            .shadow#{$infix}-#{$shadow}{
              color: var(--shadow-#{$shadow}, #{$value}) !important;
            }
          }

          @if ("#{$shadow}" == "normal") {
            .shadow#{$infix}{
              color: var(--shadow-#{$shadow}, #{$value}) !important;
            }
          }
        }
      }
    }
  }
}


:root {
  @each $color, $value in $theme-colors {
    --btn-#{$color}: var(--theme-#{$color}, #{$value});
    .btn-#{$color}{
      background-color: var(--btn-#{$color}, #{$value});
      border-color: var(--border-#{$color}, #{$value});
    }
  }
}

:root {
  @each $color, $value in $theme-colors {
    --btn-outline-#{$color}: var(--theme-#{$color}, #{$value});
    .btn-outline-#{$color}{
      border-color: var(--btn-outline-border-#{$color}, #{$value});
      color: var(--btn-outline-#{$color}, #{$value});
    }
  }
}

:root {
  @each $color, $value in $theme-colors {
    --list-group-item-#{$color}: var(--theme-#{$color}, #{$value});
    .list-group-item-#{$color}{
      background-color: var(--list-group-item-#{$color}, #{$value}) !important;
    }
  }
}


:root {
  // Form variable values only support SassScript inside `#{}`.
  @each $h, $value in $lh-sizes {
    --lh-#{$h}: #{rem-to-px($value)};
    .lh-#{$h}{
      line-height: var(--#{$h}, rem-to-px($value)) !important
    }
  }
}

:root {
  @each $h, $value in $h-sizes {
    --#{$h}: #{rem-to-px($value)};
    .#{$h}{
      font-size: var(--#{$h}, rem-to-px($value)) !important
    }
  }
}
//:root {
//  @each $text-size, $value in $text-sizes {
//    --text-#{$text-size}: #{rem-to-px($value)};
//    .text-#{$text-size}{
//      font-size: var(--text-#{$text-size}, rem-to-px($value)) !important
//    }
//  }
//}
:root {
  @each $widget, $value in $widget-sizes {
    --widget-#{$widget}: #{rem-to-px($value)};
  }
}
:root {
  @each $spacer, $value in $spacers {
    --spacer-#{$spacer}: #{rem-to-px($value)};
  }
}
:root {
  @each $rounded, $value in $rounds {
    --rounded-#{$rounded}: #{rem-to-px($value)};
  }
}
:root {
  @each $ellipsis, $value in $ellipsis-sizes {
    --ellipsis-#{$ellipsis}: #{$value};
  }
}

:root {
  // Form variable values only support SassScript inside `#{}`.
  @each $color, $value in $colors {
    --#{$color}: #{$value};
  }
}

:root {
  @each $bp, $value in $grid-breakpoints {
    --breakpoint-#{$bp}: #{$value};
  }
}

:root {
  // Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --font-family-sans-serif: #{inspect($font-family-sans-serif)};
  --font-family-monospace: #{inspect($font-family-monospace)};
}

:root {
  a {
    color: var(--link-color, $link-color);
    text-decoration: var(--link-decoration, $link-decoration);
    //background-color: transparent; // Remove the gray background on active links in IE 10.
    //
    @include hover() {
      color: var(--link-hover-color, $link-hover-color);
      text-decoration: var(--link-hover-decoration, $link-hover-decoration);
    }
  }
}
