// Typography

// [converter] $parent hack
@mixin widget-variant($parent, $color) {
  #{$parent} {
    border-top-width: var(--widget-border-top-width, 10px) !important;
    border-color: var(--widget-border-color, #{$color}) !important;
  }
}

@mixin widget-none($classname) {
  #{$classname}{
    border-top-width: 0 !important;
    >.widget-title{
      border-bottom: none;
      margin-bottom: 0;
    }
  }
}

@mixin widget-border($parent) {
  .#{$parent} {
    border-radius: var(--widget-border-radius, 5px);
    border-top: var(--#{$parent}-border-top, 0);
    border-left: var(--#{$parent}-border-left, 0);
    border-right: var(--#{$parent}-border-right, 0);
    border-bottom: var(--#{$parent}-border-bottom, 0);
    padding: var(--#{$parent}-padding, 0);
    margin: var(--#{$parent}-margin, 0);
    background-color: var(--#{$parent}-background-color, transparent);
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $next: breakpoint-next($breakpoint, $grid-breakpoints);
  $infix: breakpoint-infix($next, $grid-breakpoints);
  $size: map-get($widget-sizes, $breakpoint);
  $size-small: map-get($widget-small-sizes, $breakpoint);

  @include media-breakpoint-up($breakpoint) {
    .widget {
      @include widget-border("widget#{$infix}-title");

      &:not(.widget-small):not(.widget-box){
        .widget-title{
          font-size: var(--widget-title-font-size, #{$size});
        }
      }
      .widget-title{
        &.widget-small, &.widget-box{
          font-size: var(--widget-title-font-size, #{$size-small});
        }
      }
    }

    @include widget-none(".widget#{$infix}-none");

    @each $color, $value in $theme-colors {
      @include widget-variant(".widget#{$infix}-#{$color}", $value);
    }
  }
}
