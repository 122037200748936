
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        $length : -$length;
        .#{$abbrev}#{$infix}--#{$size} { #{$prop}: $length !important; }
        .#{$abbrev}t#{$infix}--#{$size},
        .#{$abbrev}y#{$infix}--#{$size} {
          #{$prop}-top: $length !important;
        }
        .#{$abbrev}r#{$infix}--#{$size},
        .#{$abbrev}x#{$infix}--#{$size} {
          #{$prop}-right: $length !important;
        }
        .#{$abbrev}b#{$infix}--#{$size},
        .#{$abbrev}y#{$infix}--#{$size} {
          #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l#{$infix}--#{$size},
        .#{$abbrev}x#{$infix}--#{$size} {
          #{$prop}-left: $length !important;
        }
      }
    }
  }
}
