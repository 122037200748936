.breadcrumb{ border-radius: 0;
	border: 1px solid $border-color;
	background-color: #fff;
	.breadcrumb-item{
		display: flex;
		+ .breadcrumb-item::before {
			font-family: "Material Icons";
			content: "chevron_right";
		}
	}
}
