@import '~bootstrap/scss/functions';
@import '~@xem/look-styles/src/variables';
@import '~@xem/look-styles/src/luke/mixins';


:root{
  --modal-inside-space: 230px;
  --modal-max-height: 100%;
  //--modal-min-height: 100px;

  @media screen and (max-height: 650px) {
    --modal-inside-space: 215px;
    --modal-qrcode-min-height: 100%;
  }

  @media screen and (max-height: 620px) {
    --modal-inside-space: 0px;
  }
 
}

@media screen and (max-width: 450px) {
  .modal.qrcode{
    >.modal-dialog{
      >.modal-content{
        height: 100%;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
    }
  }
}

reach-portal, [data-rsbs-modal]{
  position: relative;
  z-index: 1000;
  > [data-rsbs-overlay]{
    max-width: 450px;
    margin: 0 auto;
  }
}

@media screen and (max-height: 650px) {
  body{
    > .modal{
      &.qrcode{
        > .modal-dialog{
          > .modal-content{
            height: 100%;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
          }
        }
      }
    }
  }
}

.modal{
  .swipeable{
    height: calc(100%);
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
  }

  @include user-select(none);
  .modal{
    > .modal-dialog{
      > .modal-content{
        min-height: auto !important;
      }
    }
  }

  &{

    .fade-scale {
      -webkit-transform: scale(2);
      -moz-transform: scale(2);
      -ms-transform: scale(2);
      transform: scale(2);
      opacity: 0;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
    }

    &{
      &.show{
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;

        .fade-scale{
          opacity: 1;
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1);
        }
      }
    }

    .domain-add-root{
      &.modal-dialog{
        max-width: 800px;
      }
    }
  }


  &.animation{
    display: block;
    visibility: hidden;
    opacity: 0;
    transition-duration: var(--popup-overlay-out-transition-duration, 600ms) !important;
    animation-name: fadeOut;
    height: calc(100%) !important;

    &.colorful{
      .modal-dialog {
        .modal-content {
          max-height: 100% !important;
        }
      }
    }

    &.qrcode{
      >.modal-dialog{
        >.modal-content{
          &, &> .swipeable{
            >.modal-body{
              min-height: calc(100% - 276px - 53px);
            }

            >.modal-footer{
              z-index: 1;
              border: none;
              padding: 0;
              box-shadow: var(--shadow-footer, 1px 2px 8px 0px rgba(0,0,0,0.16)) !important;
            }
          }
        }
      }
      //& > .modal-dialog > .modal-content{
      //  .modal-body{
      //    min-height: 350px;
      //  }
      //}

      & > .modal-dialog > .modal-content{
        & {
          & .modal {
            & {
              margin-top: var(--modal-inside-space) !important;
              height: calc(100% - var(--modal-inside-space)) !important;
              //min-height: 450px !important;
            }
          }
        }
      }
    }

    .modal-overlay{
      background-color: rgba(33, 33, 33, 0.68);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      transition-property: transform;
      transition-duration: var(--popup-overlay-transition-duration, 600ms);
      animation-duration: var(--popup-overlay-transition-duration, 600ms);
    }

    .modal-dialog{
      z-index: 2;
      height: calc(100%) !important;
      max-width: 100%;
      .modal-content{
        & {
          max-height: var(--modal-max-height, 100%);
          min-height: var(--modal-min-height, 100px);
          &, & > .swipeable{
            .modal-body:not(.withoutscroll) {
              overflow-x: hidden;
              overflow-y: auto;
            }
          }
          max-width: 500px;
          margin: 0 auto;
          border: none;
          border-radius: 0;
          height: var(--popup-content-height, auto);
          background-color: var(--modal-background-color, #F2F2F2);
          transition-property: transform;
          transition-duration: var(--popup-transition-duration, 600ms);
          transform: translate3d(0, 100%, 0);
        }
      }
    }

    &.in .modal:not(.in){
      visibility: hidden;
      opacity: 0;
      .modal-dialog{
        .modal-content{
          &{
            transform: translate3d(0, 100%, 0) !important;
          }
        }
      }
    }

    &.in{
      &, .modal.in{
        z-index: 9990;
        visibility: visible;
        opacity: 1;
        .modal-overlay{
          animation-name: fadeIn;
        }

        .modal-dialog{
          .modal-content{
            &{
              transform: translate3d(0px, 0px, 0px);
            }
          }
        }
      }
    }
  }


  &{
    &.modal-small {
      @media screen and (min-width: 450px) {
        .modal-dialog {
          .modal-content {
            max-width: 375px;
          }
        }
      }
    }
  }
}
