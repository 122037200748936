@import './variables_before';
@import '~bootstrap/scss/variables';
$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex;
$rowsizes: () !default;
$rowsizes: map-merge((
        "n": $grid-gutter-none-width,
        "mn": $grid-gutter-mini-width,
        "s": $grid-gutter-small-width,
        "m": $grid-gutter-medium-width,
        "b": $grid-gutter-big-width,
        "none": $grid-gutter-none-width,
        "mini": $grid-gutter-mini-width,
        "small": $grid-gutter-small-width,
        "normal": $grid-gutter-width,
        "medium": $grid-gutter-medium-width,
        "big": $grid-gutter-big-width
), $rowsizes);

$rotates: () !default;
$rotates: map-merge((
        "0": 0,
        "45": 45deg,
        "90": 90deg,
        "135": 135deg,
        "180": 180deg,
        "225": 225deg,
        "270": 270deg,
        "315": 315deg,
        "360": 0
), $rotates);


$rowcolumns: () !default;
$rowcolumns: map-merge((
        "1": "1",
        "2": "2",
        "3": "3",
        "4": "4",
        "5": "5",
        "6": "7",
        "7":"7",
        "8":"8",
        "9":"9",
        "10": "10",
        "11": "11"
), $rowcolumns);

@function parseInt($n) {
  @return $n / ($n * 0 + 1);
}

//convert rem back to px

@function rem-to-px($size) {
  $value: parseInt($size) * 16px; //16px is the base size specified
  @return round($value);
}
$alert-bg-level:                    0;
$alert-border-level:                1;
$alert-color-level:                 -10;

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

$grid-breakpoints: map-merge($grid-breakpoints, (
        vl   : 1600px
));

$container-max-widths: map-merge($container-max-widths, (
        vl   : 1500px
));

$colors: map-merge((
        "blue"        : $blue,
        "indigo"      : $indigo,
        "purple"      : $purple,
        "pink"        : $pink,
        "red"         : $red,
        "orange"      : $orange,
        "yellow"      : $yellow,
        "green"       : $green,
        "teal"        : $teal,
        "cyan"        : $cyan,
        "gray"        : $gray-600,
        "gray-dark"   : $gray-800,
        "white"       : $white,
        "transparent" : ""
), $colors);


$widget-sizes: (
        xs: $h3-font-size,
        sm: $h3-font-size,
        md: $h3-font-size,
        lg: $h3-font-size,
        xl: $h3-font-size,
        vl: $h3-font-size
);

$widget-small-sizes: (
        xs: $h4-font-size,
        sm: $h4-font-size,
        md: $h4-font-size,
        lg: $h4-font-size,
        xl: $h4-font-size,
        vl: $h4-font-size
);


$page-title-sizes: (
        xs: $h3-font-size,
        sm: $h3-font-size,
        md: $h2-font-size,
        lg: $h1-font-size,
        xl: $h1-font-size,
        vl: $h1-font-size
);


$ellipsis-sizes: (
        five: 5,
        four: 4,
        three: 3,
        two: 2,
        one: 1
);

$auto-sizes: (
        five: 5,
        four: 4,
        three: 3,
        two: 2,
        one: 1
);


$spacer: 1rem;
$spacers: (
        0: 0,
        1: ($spacer * .3125),
        2: ($spacer * .625),
        3: ($spacer * .9375),
        4: ($spacer * 1.5625),
        5: ($spacer * 3.125),
        6: ($spacer * 4.0625),
        7: ($spacer * 5),
        8: ($spacer * 6),
        9: ($spacer * 7),
        10: ($spacer *9)
);


$rounded: 1rem;
$rounds: (
        0: 0,
        1: ($rounded * .3125),
        2: ($rounded * .625),
        3: ($rounded * .9375),
        4: ($rounded * 1.5625),
        5: ($rounded * 3.125),
        6: ($rounded * 4.0625),
        7: ($rounded * 5),
        8: ($rounded * 6),
        9: ($rounded * 7),
        10: ($rounded *9)
);
