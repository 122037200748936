//
//.flagFont{
//  font-family: 'flagFont';
//}
.justify-content-evenly {
  justify-content: space-evenly!important;
}

.price{
  position: relative;
  span{
    font-size: 11px;
    margin-left: 1px;
  }
}
[contenteditable] {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

[data-select-none]{
  @include user-select(none);
}
.ck.ck-editor__main > .ck-editor__editable {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.alert{
  z-index: 9000 !important;
}
#root {
  &,.sticky{
    display: flex;
    flex-direction: column;
  }
}

.active {
  color: #000;
}

.highcharts-credits{
  display: none !important;
}
.lang-section {
  button {
    cursor: pointer;
    &.active {
      border: 1px solid #000;
    }
  }
}

.menu-center {
  display: flex;
  align-items: center;
}

.pagination {
  .page-item:focus,
  .page-link:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.pagination-select {
  width: 30%;
  margin-bottom: 20px;
}

@media print {
  .no-print {
    display: none;
  }
}

.flex-1 {
  -webkit-flex: 1 1 0%;
  flex: 1 1;
}

.flex {
  display: -webkit-flex;
  display: flex;
}


.square {
  position: relative;
  width: 50%;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}



.btn:focus {
  box-shadow: none !important;
}

.hidden{
  overflow: hidden;
}

.card-header{
  &.card-header-none{
    padding: 0;
    margin-bottom: 0px;
  }
}

.table-virtualized{
  .row{
    padding: 0px;
    margin: 0px;
    & > [class*="col"], & > [class*="col-"] {
      padding: 10px 0px;
      background-color: #fff;
    }

  }

  >.table-head.row{
    border-bottom: 2px solid $border-color;
  }

  > .tbody{
    > .row{
      & > [class*="col"],  & > [class*="col-"] {
        border-bottom: 1px solid $border-color;
        background-color: #fff;
      }
    }
  }
}

th, td{
  &.wao{
    table-layout: auto;
    white-space: nowrap;
  }
}

.wao{
  width: auto !important;
}

.zIndex{
  z-index: 99999 !important;
  position: relative;
}

.cls-1 {
  fill: #001b55;
}

.cls-1, .cls-2, .cls-3 {
  fill-rule: evenodd;
}

.cls-2 {
  fill: #800034;
}

.cls-3 {
  fill: #00314c;
}


.suggestion-localtion{
  width: 100%;
  .suggestion-item{
    background-color: #fff;
  }
  .suggestion-item--active{
    border-color: $border-color;
    background-color: $gray-500;
  }
}

.animate-content {
  display: none;
  opacity: 0;
  overflow: hidden;
}

.details.toolbar{
  .btn-group{
    .btn{
      border-top-radius: 0;
      padding: 5px;
      padding-bottom: 0;
      color: #fff;
    }

    .btn:first-child{
      border-top-left-radius: 0;
    }

    .btn:last-child{
      border-radius: 0;
    }
  }
}



html.web {
  .logo{
    color: $yellow;
    .st0{fill:$yellow !important;}
    .st1{fill:$white !important;}
  }
}

.isBg{
  svg{
    color: transparent;
    fill: transparent;
  }
}



.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
  &:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 .5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #818078;
    background-color: #fcfcfa;
  }
}
