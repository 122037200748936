html{
  &.youtube{
    .MuiPage-root {
     &.page{

       @media screen and (min-width: 414px) {
         .video-wrapper, .embed-video {
           width: 414px !important;
           height: 234px !important;
         }
       }

       @media screen and (max-width: 413px) {
         .video-wrapper, .embed-video {
           width: 390px !important;
           height: 219px !important;
         }
       }

       &, &>.container{
         overflow-y: auto;
         height: calc(100%);
       }

       &>.container > .hidden-x{
         min-height: calc(100% - 40px);
       }
     }
    }
  }

  .youtube-flash{
    display: none;
  }

  &.redirect, &.youtube{
    &:root{
      --body-bg-color: #181818;
      --footer-height: 50px !important;
      --button-bar-height: 50px !important;
    }

    &.locked{
      &:root{
        --body-bg-color: #fff;
      }
    }

    &, body, .view-main {
      background-color: var(--body-bg-color);
    }
    .view-main {
      overflow-y: auto;
    }
  }


  &.youtube {
    @media screen and (max-width: theme-width('md')) {
      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .video-wrapper{
      max-width: 100%;
    }

    .youtube-flash{
      display: flex;
      //position: fixed;
      z-index: 9999;
    }

    &:root{
      --youtube--color: #fff;
    }

    .widget{


      --widget-font-size: 14px;
      --widget-title-padding-top: var(--widget-title-padding-y);
      --widget-title-padding-bottom: var(--widget-title-padding-y);
      --widget-title-padding-left: var(--widget-title-padding-x);
      --widget-title-padding-right: var(--widget-title-padding-x);


      --widget-body-padding-top: var(--widget-body-padding-y);
      --widget-body-padding-bottom: var(--widget-body-padding-y);
      --widget-body-padding-left: var(--widget-body-padding-x);
      --widget-body-padding-right: var(--widget-body-padding-x);


      --widget-bg-color: transparent;
      --widget-padding-top: var(--widget-padding-y);
      --widget-padding-bottom: var(--widget-padding-y);
      --widget-padding-left: var(--widget-padding-x);
      --widget-padding-right: var(--widget-padding-x);

      &{
        padding-top: var(--widget-padding-top, 0);
        padding-bottom: var(--widget-padding-bottom, 0);
        padding-left: var(--widget-padding-left, 0);
        padding-right: var(--widget-padding-right, 0);
      }

      &{
        margin-bottom: var(--widget-margin-bottom, 0);
        margin-top: var(--widget-margin-top, 0);
        margin-left: var(--widget-margin-left, 0);
        margin-right: var(--widget-margin-right, 0);
      }



      &{
        color: var(--widget--color, #fff);
        font-size: var(--widget-font-size);
        background-color: var(--widget-bg-color, inherit);
      }

      a{
        color: var(--widget-link-color, #fff);
        &:hover{
          color: var(--widget-link-hover-color, #fff);
        }

      }


      .item-link {
        color: var(--widget-link-color, #3EA6FF);
        &:hover{
          color: var(--widget-link-hover-color, #3EA6FF);
        }
      }

      .widget-title{
        &{
          padding-top: var(--widget-title-padding-top, 0);
          padding-bottom: var(--widget-title-padding-bottom, 0);
          padding-left: var(--widget-title-padding-left, 0);
          padding-right: var(--widget-title-padding-right, 0);
        }

        &{
          margin-bottom: var(--widget-title-margin-bottom, 0);
          margin-top: var(--widget-title-margin-top, 0);
          margin-left: var(--widget-title-margin-left, 0);
          margin-right: var(--widget-title-margin-right, 0);
        }

        background-color: var(--widget-title-bg-color, inherit);
      }


      .widget-body{
        &{
          padding-top: var(--widget-body-padding-top, 0);
          padding-bottom: var(--widget-body-padding-bottom, 0);
          padding-left: var(--widget-body-padding-left, 0);
          padding-right: var(--widget-body-padding-right, 0);
        }

        &{
          margin-top: var(--widget-body-margin-top, 0);
          margin-bottom: var(--widget-body-margin-bottom, 0);
          margin-left: var(--widget-body-margin-left, 0);
          margin-right: var(--widget-body-margin-right, 0);
        }
      }
    }


    .youtube-flash{
      width: 100%;
      background-color: var(--body-bg-color);
      height: calc(var(--button-bar-height));
    }

    td.MuiTableCell-root{
      border-bottom: none;
    }


    &, body, #__next {
      .view-main {
        max-height: calc(100% - var(--button-bar-height));
        height: calc(100% - var(--button-bar-height));
      }
    }
  }
}
