.entity-image{
	position: relative;
	.toolbars{
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.5);
		-webkit-box-align: center !important;
		align-items: center !important;
		display: -webkit-box !important;
		display: flex !important;
		z-index: 999;
		.btn{
			color: #fff;
		}

	}
}
