
.panel:not(.panel-in):not(.panel-out):not(.panel-in-breakpoint):not(.panel-in-collapsed):not(.panel-in-swipe) {
  display: none;
}

.panel {
  min-width: 100px;
  max-width: 90vw;
  z-index: 1;
  box-sizing: border-box;
  position: absolute;
  //top: var(--f7-appbar-app-offset,0px);
  //height: calc(100% - var(--f7-appbar-app-offset,0px));
  transform: translate3d(0,0,0);
  width: var(--f7-panel-width);
  background-color: var(--f7-panel-bg-color);
  overflow: visible;
}

.panel-left {
  left: - var(--f7-panel-left-width,var(--f7-panel-width));
  width: var(--f7-panel-left-width,var(--f7-panel-width));
}



html{

  header.appBar{
    z-index: 1000 !important;
  }

  .panel{
    transition-property: transform;
    transition-duration: var(--f7-panel-transition-duration);
    &.panel-left{
      opacity: 0;
      position: fixed;
      height: calc(100% - var(--f7-appbar-app-offset,0px));
      background-color: var(--f7-panel-left-background-color, gray);
    }
  }

  &.with-panel-left-reveal:not(.user-not-login){
    body{ @include user-select(none); }
    #root{ overflow: hidden; }
    header.sticky, .view.view-main, #bottom-toolbar {
      transform: translate3d(calc(1 * var(--f7-panel-left-width,var(--f7-panel-width))),0,0);
      margin-left: 0 !important;
      @include user-select(none);
    }

    .main-overlay{
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 999;
      opacity: 0.6;
      background-color: #212121;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .panel{
      &.panel-left{
        opacity: 1;
        left: 0;
        z-index: 99999 !important;
      }
    }
  }

  .panel {
    &.panel-left {
      .nav{
        .nav-item{
          width: 100%;
          a {
            &.nav-link {
              width: 100%;
              &{
                color: color('white');
              }

              span + span{
                margin-left: 5px;
              }

              &:hover{
                background-color: var(--f7-link-bg-color);
                color: color('yellow');
                span.link-icon{
                  -webkit-animation: swing ease-in-out 0.5s 1 alternate;
                  animation: swing ease-in-out 0.5s 1 alternate;
                }
              }
            }
          }
        }
      }
    }
  }
}



html:not(.with-panel-mobile-reveal){
  .ck-content{
    min-height: 250px;
  }
}

html{
  @media screen and (min-width:1500px){
    .panel{
      &.panel-left{
       display: block !important;
      }
    }

    &.with-panel-left{
      header.sticky{
        width: calc(100% - var(--f7-panel-left-width,var(--f7-panel-width)));
      }
      header.sticky, .view.view-main{
        margin-left: var(--f7-panel-left-width,var(--f7-panel-width));
      }
      .panel{
        &.panel-left{
          opacity: 1;
        }
      }
    }
  }
}


html{
  &.cpanel{
    .container-fluid{
      padding: 0;
      max-width: 375px;
      min-width: 375px;
      margin: 0 auto;
    }

    @media screen and (max-width: 450px) {
      .container-fluid{
        max-width: 100%;
      }
    }
  }
}