// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin make-col-right-offset($size, $columns: $gr_assert-ascendingid-columns) {
  $num: $size / $columns;
  margin-right: if($num == 0, 0, percentage($num));
}

.row-stretch, .row-center{
  display: -webkit-box !important;
  display: flex !important;
}

.row-center{
  -webkit-box-align: center !important;
  align-items: center!important;
}

.row-stretch{
  -webkit-box-align: stretch !important;
  align-items: stretch !important;
}



@mixin make-row-2($grid-gutter-width) {
  display: flex;
  flex-wrap: wrap;
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);

  & > [class*="col"],
  & > [class*="col-"]{
    padding-left: ($grid-gutter-width/2);
    padding-right: ($grid-gutter-width/2);
  }

  &.row-stretch{
    -webkit-box-align: stretch !important;
    align-items: stretch !important;

    & > [class*="col"],
    & > [class*="col-"]{
      margin-bottom: 0;
      padding-bottom: $grid-gutter-width;
    }
  }
}

@mixin make-columns($sizes: $rowsizes, $columns: $grid-columns, $breakpoints: $grid-breakpoints) {
  @each $type, $gutter-width in $sizes {
    .row-#{$type} {
      @include make-row-2($gutter-width);
    }
  }

  @each $type, $gutter-width in $sizes {
    @each $breakpoint in map-keys($breakpoints) {
      $infix: breakpoint-infix($breakpoint, $breakpoints);
      @include media-breakpoint-up($breakpoint, $breakpoints) {
        @if not ($infix == "") { // Avoid emitting useless .offset-0
          .row#{$infix}-#{$type} {
            @include make-row-2($gutter-width);
          }
        }

        @for $i from 0 through ($columns - 1) {
          @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
            .offset-right#{$infix}-#{$i} {
              @include make-col-right-offset($i, $columns);
            }
          }
        }
      }
    }
  }
}

@mixin make-cols($columns: $grid-columns, $breakpoints: $grid-breakpoints) {
  @if $columns > 0 {
    @each $breakpoint in map-keys($breakpoints) {
      $infix: breakpoint-infix($breakpoint, $breakpoints);
      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }
    }
  }
}

@mixin make-rows($columns: $grid-columns, $breakpoints: $grid-breakpoints) {
  @for $type from 1 through $columns {
    .row-#{$type} {
      @include make-cols($type, $breakpoints)
    }
  }

  @for $type from 1 through $columns {
    @each $breakpoint in map-keys($breakpoints) {
      $infix: breakpoint-infix($breakpoint, $breakpoints);
      @include media-breakpoint-up($breakpoint, $breakpoints) {
        @if not ($infix == "") { // Avoid emitting useless .offset-0
          .row#{$infix}-#{$type} {
            @include make-cols($type, $breakpoints)
          }
        }
      }
    }
  }
}
