
.card {
  margin-bottom: 15px;


  &.card-dirty{
    //margin-bottom: calc(-100px);
    //height: auto !important;
    z-index: 50;
  }

  &.bg-red{
    .card-header{
      background-color: #db462d;
      color: #fff;
    }
  }

  &.card-none{
    border: none;
    background-color: transparent;
  }
  .card-body+.card-body{
    margin-top: 0;
    padding-top: 0;
  }
  /* Comment */
  &.card-comment{
    border: none;
    font-size: 13px;
    .card-title{
      font-size: 14px;
      font-weight: bold;
    }
    .card-body{
      padding: 0;
    }

    .card-header{
      border-bottom: none;
    }

    .card-footer{
      background-color: transparent;
      border-top: none;
      border-bottom: 1px solid $border-color;
      border-radius: 0;
      padding-left: 0;
    }

    .card-created{
      .not-verified{
        padding-left: 20px;
        font-weight: bold;
      }
    }
    .icon{
      font-size: 14px;
    }

  }

  /* Guide */
  &.card-guide{
    border: none;
    .card-item{
      height: calc(100%);
      &:hover{
        .card-image{
          -webkit-box-shadow: 0 1px 8px rgba(0,0,0,.5);
          box-shadow: 0 1px 8px rgba(0,0,0,.5);
          padding: 0;
        }
      }
    }

    .card-body{
      padding: 0;
    }
    .card-title, .card-desc{
      margin-top: 8px;
      margin-bottom: 0px;
    }
    .card-title{
      overflow: hidden;
      max-height: 45px;
    }
    .card-desc{
      overflow: hidden;
      max-height: 45px;
    }
  }
}


.card-header {
  display: flex;
  flex-direction: row;
  //min-height: 3.75rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  flex-grow: 1;
  align-items: center;
  & > * {
    flex: 1 1;
  }
}

.card-title
{
  margin-bottom: .5rem;
}

.card-header-title
{
  margin-bottom: 0;
}

.card-header-tabs
{
  margin-top: -.5rem;
  margin-right: 0;
  margin-bottom: -.5rem;
  margin-left: 0;
}

.card-header-tabs {
  &.nav-link {
    padding-top: calc((3.75rem - 1em * 1.5) / 2) !important;
    padding-bottom: calc((3.75rem - 1em * 1.5) / 2) !important;
  }
}

.card-table {
  margin-bottom: 0;

  & thead th {
      border-top-width: 0;
    }

  & thead th:first-child,
    tbody td:first-child {
      padding-left: 1.5rem !important;
    }

  & thead th:last-child,
   tbody td:last-child {
      padding-right: 1.5rem !important;
    }
}

.card-avatar
{
  display: block !important;
  margin-right: auto;
  margin-bottom: 1rem;
  margin-left: auto;
}

.card-avatar-top
{
  margin-top: -3rem;

  &.avatar-xs
  {
    margin-top: -2.3125rem;
  }

  &.avatar-sm
  {
    margin-top: -2.75rem;
  }

  &.avatar-lg
  {
    margin-top: -3.5rem;
  }

  &.avatar-xl
  {
    margin-top: -4.0625rem;
  }

  &.avatar-xxl
  {
    margin-top: -5.5rem;
  }
}

.card-dropdown
{
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.card-inactive
{
  border-style: dashed;
  border-color: #e3ebf6;
  background-color: transparent;
  box-shadow: none;
}

.cart{
  .card{
    img{
      max-width: 100px;
    }
  }
}

.checkout-cart{
  .card{
    img{
      max-width: 80px;
      padding-left: 0.5rem;
    }
  }
}


.card-grid, .card-entry{
  .card-auth {
    padding-left: 0;
    margin: 0;
    letter-spacing: 0.04em;
    li {
      display: inline-block;
      font-size: 12px;
      font-weight: 600;
      color: #83858F;
    }

    *{
      word-spacing: 0.2rem
    }

    .entry__meta-author ~ *,
    .entry__meta-author span ~ *{
      margin-left: 3px;
    }
    .entry__meta-author + .entry__meta-view{
      &::before{
        content: "-";
        display: inline-block;
        padding-right: 3px;
        padding-left: 0;
        color: #6c757d;
      }
    }
  }

}


.card-grid{
  .card-item {
    &.card-none{
      .card{
        box-shadow: none !important;
        padding: 1px;
      }
    }
    .card{
      -ms-flex-direction: column!important;
      flex-direction: column!important;
      .card-image, .card-body {
        -webkit-box-flex: 0;
        -ms-flex: none;
        flex: none;
        width: 100%;
        text-align: center;
        img{
          margin: auto;
        }
      }
    }
  }

  .slick-slider{
    overflow: hidden;
    .slick-list{
      margin: 0 -8px 0 -10px;
      .card-item {
        padding: 0 10px 0 10px;
      }
    }
  }
}


.entity-small, .entity-mini{
	.card-title{
		font-size: 1rem;
		font-weight: bold;
	}
  > .card-item{
    border-radius: 4px;
  }
}
