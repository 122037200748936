/**

 */

:root{
  --gray-1: rgba(61, 37, 20, .08)
}
span[data-tiptap-tooltip]{
  background-color: #686868;
  border-radius: 5px;
  align-items: center;
  padding: 5px 10px;
  [data-tiptap-title]{
    font-size: 12px;
    color: #ffffff;
  }
  [data-tiptap-cmd]{
    kbd{
      font-size: 10px;
      padding: 2px 5px;
      margin-left: 5px;
    }
  }
}

.tiptap{
  :first-child {
    margin-top: 0;
  }

  table {
    border-collapse: collapse;
    margin: 0;
    table-layout: fixed;
    width: 100%;
    td, th{
      border:1px solid var(--gray-1);
      box-sizing:border-box;
      min-width:1em;
      padding:6px 8px;
      position:relative;
      vertical-align:top
    }
    td>*, th>*{margin-bottom:0}
    th{background-color:var(--gray-1);font-weight:700;text-align:left}

    th, td{
      & {
        .column-resize-handle {
          background-color: var(--theme-purple);
          bottom: -2px;
          pointer-events: none;
          position: absolute;
          right: -2px;
          top: 0;
          width: 4px;
        }
      }
    }

    :is(.selectedCell) {
      border-color: #00000005;
      background-color: #00000035;
    }
  }
}

.resize-cursor{
  cursor: col-resize;
}

.tippy-box{

//{
//  bottom: calc(var(--ck-balloon-arrow-height)* -1);
//  left: 50%;
//  margin-left: calc(var(--ck-balloon-arrow-half-width)* -1);
//}

  &:root{
    --tiptap-border-color: #21212119
  }
  .tippy-arrow{
    &:before, &:after{
      content: '';
      position: absolute;
      border-color: transparent;
      border-style: solid;
      margin: 0 3px;
    }
  }

  &[data-placement^=top]>.tippy-arrow {
    &:before, &:after{
      border-width: 8px 8px 0;
      transform-origin: 50% 0;
    }

    &:before{
      bottom: -7px;
      border-top-color: var(--tiptap-border-color, #21212119);
    }
    &:after{
      bottom: -6px;
      border-top-color: #fff;
    }
  }

  &[data-placement^=bottom]>.tippy-arrow {
    &:before, &:after{
      border-width: 0 8px 8px;
      transform-origin: 50% 7px;
    }

    &:before{
      top: -42px;
      border-bottom-color: var(--tiptap-border-color, #21212119);
    }

    &:after{
      top: -40px;
      border-bottom-color: #fff;;
    }
  }
}


.ProseMirror{
  &:is(.selection), &:is(*)::selection {
    background-color: rgb(255 255 255 / 0.2);
  }
  .selection, *::selection {
    display: inline-block;
    background-color: rgb(0 0 0 / 0.1);
  }

  .node-imageUpload {
    background-color: #f3f3f3;
    border-radius: 0.25rem;
    border-width: 2px;
    border-style: dotted;
    border-color: rgb(0 0 0 / 1);
    padding: 0.5rem;

    & .overflow-hidden { width: 0;  height: 0 }

    & .image-wrapper{
      padding: 10px;
      & .image-upload-text {
        padding-bottom: 10px;
      }
      & .image-btn, & .image-upload-text {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center
      }
    }

  }

  :is(.node-imageUpload) {
    border-color: rgb(115 115 115 / 1);
  }


  .node-imageBlock {
    & img {
      @apply border-2 border-transparent rounded-xl overflow-hidden;
    }

    &:hover img {
      @apply border-2 border-neutral-100 dark:border-neutral-900;
    }

    &:has(.is-active) img,
    &.has-focus img {
      @apply border-2 border-neutral-800 dark:border-neutral-200;
    }
  }
}


