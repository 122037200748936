
.aspect-ratio{
  &{
    color: $border-color;
  }

  &, svg{
    max-width: 100%;
    height: auto !important;
  }

  &.loaded{
    color: transparent;
  }
}

.bg-white{
  &.card-header{
    border-bottom: none;
    font-size: 1rem;
    font-weight: 500;
    line-height: 2.4rem !important;
    min-height: 2.4rem !important;
    padding-top: 20px;
    padding-bottom: 0;
  }
}

.popover{
  &.filter{
    .card-body{
      .form-group:last-child{
        margin-bottom: 0;
      }
    }

    .card-footer{
      background-color: transparent;
    }
  }
}

.table{
  .caption-top{
    caption-side: top;
    background-color: theme-color('light');
    border: 1px solid $border-color;
    padding: 7px;
    margin: 0 0 -1px;
  }
  td{
    .form-group{
      margin-bottom: 0;
    }

  }
}

.aspect-ratio{
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  display: block;
  background: #fafbfc;
  color: #d3dbe2;
  svg{
    width: 100%;
    height: 100%;
  }
  &:before{
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid rgba(195,207,216,.3);
  }
}

.MuiTypography-root{
  .aspect-ratio{
    background-color: transparent;
    &:before{
      border: none !important;
    }
  }
}


.table-list{
  position: relative;
  thead{
    position: relative;
  }
}

.form-group{
  .input-group + .description{
    color: color('gray');
    text-decoration: ivory;

  }
}
