.phone-area{
  .MuiInput-underline{
    &:before, &:after{
      border: none !important;
    }
  }

  .MuiInput-input{
    div[class*="IndicatorsContainer"]{
      div[class*="makeStyles-root"]{
        padding: 0;
      }
    }
  }
}