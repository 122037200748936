// Contextual backgrounds

// [converter] $parent hack
//@mixin bg-variant($parent, $color) {
//  #{$parent} {
//    background-color: $color;
//  }
//  a#{$parent}:hover {
//    background-color: darken($color, 10%);
//  }
//}
//

@mixin extend-button-variant($bg) {
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 0 0 rgba(22,29,37,.05);
  box-shadow: 0 1px 0 0 rgba(22,29,37,.05);
  background: -webkit-gradient(linear,left top,left bottom,from(rgba($bg,.05)),to($bg));
  background: linear-gradient(to bottom,rgba($bg,.05),$bg);
  -webkit-transition-property: background,border,-webkit-box-shadow;
  transition-property: background,border,-webkit-box-shadow;
  transition-property: background,border,box-shadow;
  transition-property: background,border,box-shadow,-webkit-box-shadow;
  -webkit-transition-timing-function: cubic-bezier(.64,0,.35,1);
  transition-timing-function: cubic-bezier(.64,0,.35,1);
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-tap-highlight-color: transparent;
}
