.is-invalid {
  & ~ .invalid-feedback {
    display: block;
  }
  .MuiOutlinedInput-notchedOutline{
    border-color: #f44336;
  }
}

.form-select__menu{
  z-index: 900000099999999 !important;
}
.MuiInputBase-formControl{
  > .form-select__container{
    width: 100%;
   .form-select__control{
     padding: 10px;
     border: none;
     box-shadow: none;
     &:hover, &:focus{
       border-color: none;
     }
    }
  }
}

.select-toolbar{
  position: absolute;
  min-width: 300px;
  z-index: 100;
  top: 0;
  .btn{
    padding: 0.175rem 0.35rem;
    text-transform: none !important;
  }
}

.bs-popover-auto{


  .arrow::before, .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    margin-left: 10px;
  }

  &[x-placement^="bottom"], &[x-placement^="bottom-end"]{
    margin-top: 11px !important;
    margin-left: -6px !important;
    .arrow{
      margin-top: -8px;
      &::before, &::after{
        top: -8px !important;
      }
    }
  }

  &[x-placement^="bottom"] > .arrow::after {
    top: -7px !important;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
  }


  &[x-placement^="bottom-end"] > .arrow{
    &::after, &::before {
      margin-left: auto;
      right: 20px;
    }
  }

  &[x-placement^="top"], &[x-placement^="top-end"]{
    margin-bottom: 11px !important;
    .arrow{
      margin-bottom: -8px;
      &::before{
        bottom: -8px !important;
      }

      &::after{
        bottom: -7px !important;
      }
    }
  }

  &[x-placement^="top-end"] > .arrow{
    &::after, &::before {
      margin-left: auto;
      right: 5px;
    }
  }


  &.dropdown-menu{
    border: none;
    background-color: transparent;
  }

  &> .popover-inner, .dropdown-menu-inner{
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid $border-color;
  }
}

