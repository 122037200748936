[data-modal-popup] {
  &{
    --modal-popup--overlay-rounded: 16px;
  }
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  width: calc(100%);
  height: calc(100%);
}

[data-modal-popup] {
  &[data-modal-max-height="true"]{
    &{
      --modal-popup--overlay-rounded: 0px;
    }
  }
}

[data-modal-wrapper]{
  position: relative;
}

[data-modal-content]{
  [data-modal-wrapper]{
    z-index: 1001;
  }

  > [data-modal-popup]{
    &, &> [data-modal-wrapper]{
      z-index: 1002;
    }
  }
}

[data-modal-content]{
  &{
    &:before{
      position: absolute;
      content: '';
      display: block;
      width: 36px;
      height: 5px;
      top: calc(5px + env(safe-area-insebet-top));
      left: calc(calc(100% - 36px)/2);
      border-radius: 2px;
      background-color: var(--modal-popup--handle-bg, #ffffff);

      transform: translate3d(0px, -15px, 0);
      z-index: 99999;
    }
  }
}


[data-modal-content] {
  max-height: var(--modal-popup--content-max-height, 100%);
  background-color: var(--modal-popup--content-bg, #fff);
}

[data-modal-overlay], [data-modal-header]{
  border-top-left-radius: var(--modal-popup--overlay-rounded);
  border-top-right-radius: var( --modal-popup--overlay-rounded);
}

[data-modal-overlay] {
  display: flex;
  background: var(--modal-popup--bg, #fff);
  flex-direction: column;
  height: var(--modal-pre-resize-h, var(--modal-popup--overlay-h, auto));
  //transform: translate3d(0, var(--modal-popup--overlay-translate-y), 0);
  will-change: height;
}

[data-modal-overlay]:focus {
  outline: none;
}
[data-modal-is-blocking='false'] [data-modal-overlay] {
  box-shadow: 0 -5px 60px 0 rgba(38, 89, 115, 0.11),
  0 -1px 0 rgba(38, 89, 115, 0.05);
}
[data-modal-overlay],
[data-modal-root]:after {
  max-width: var(--modal-popup--max-w);
  margin-left: var(--modal-popup--ml);
  margin-right: var(--modal-popup--mr);
}
[data-modal-overlay],
[data-modal-backdrop],
[data-modal-root]:after {
  z-index: 3;
  overscroll-behavior: none;
  touch-action: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
[data-modal-backdrop] {
  top: 0;
  bottom: 0;
  background-color: var(--modal-popup--backdrop-bg, rgba(33, 33, 33, 0.68));
  will-change: opacity;
  cursor: pointer;
  opacity: 1;
  position: absolute;
}
[data-modal-is-dismissable='false'] [data-modal-backdrop] {
  cursor: ns-resize;
}

[data-modal-root]:after {
  content: '';
  pointer-events: none;
  background: var(--modal-popup--bg);
  height: 0px;
  transform-origin: bottom;
  transform: scale3d(1, var(--modal-popup--antigap-scale-y), 1);
  will-change: transform;
}
[data-modal-footer],
[data-modal-header] {
  flex-shrink: 0;
  cursor: ns-resize;
  padding: 0;
}

[data-modal-header] {
  text-align: center;
  user-select: none;
  box-shadow: 0 1px 0
  rgba(46, 59, 66, calc(var(--modal-popup--content-opacity) * 0.125));
  z-index: 1;
  padding-bottom: 0;
}

[data-modal-scroll] {
  flex-shrink: 1;
  flex-grow: 1;
  -webkit-tap-highlight-color: revert;
  -webkit-touch-callout: revert;
  -webkit-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  overflow: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}
[data-modal-scroll]:focus {
  outline: none;
}

[data-modal-content] {
  /* The overflow hidden is to ensure any margin on child nodes are included when the resize observer is measuring the height */
}
[data-modal-footer] {
  box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(var(--modal-popup--content-opacity) * 0.125)),
  0 2px 0 var(--modal-popup--bg);
  overflow: hidden;
  z-index: 1;
  padding-bottom: calc(0px + env(safe-area-inset-bottom));
}


[data-modal-popup]{
  &, & > .step{
    >[data-modal-backdrop]{
      visibility: hidden;
      opacity: 0;
      background-color: rgba(33, 33, 33, 0.68);
      transition-duration: var(--popup-backdrop-transition-duration, 100ms);
      //animation-mail: fadeOut;
    }

    >[data-modal-overlay]{
      visibility: hidden;
      //animation-mail: fadeOut;
      opacity: 0;
      transition-duration: var(--popup-overlay-transition-duration, 380ms);
      animation-duration: var(--popup-overlay-transition-duration, 380ms);
      transform: translate3d(0px, 100%, 0);
    }
  }

  &.qrcode, &.in, &.out{
    z-index: 1002;
  }

  [data-modal-confirm]{
    margin: 20px auto;
    justify-content: center!important;
    align-items: center!important;
    display: flex !important;
    bottom: 50% !important;
    transform: translate3d(0, var(--modal-popup--overlay-translate-y, 50%), 0) !important;
  }


  &.in{

    &, & > .step{
      >[data-modal-backdrop], >[data-modal-overlay]{
        visibility: visible;
      }

      >[data-modal-backdrop]{
        opacity: 1;
        transition-property: transform;
      }

      >[data-modal-overlay]{
        opacity: 1;
        transform: translate3d(0px, var(--modal-popup--overlay-translate-y, 0), 0);

        &[data-modal-confirm]{
          min-height: 184px;
          &[data-modal-overlay] {
            border-bottom-left-radius: var(--modal-popup--overlay-rounded);
            border-bottom-right-radius: var(--modal-popup--overlay-rounded);
          }
        }
      }

      &.out{
        [data-modal-overlay]{
          transform: translate3d(0px, 100%, 0)
        }
      }
    }
    &.inherit{
      >[data-modal-overlay] {
        transition-duration: inherit !important;
        animation-duration: inherit !important;
      }
    }
  }
}

@media screen and (min-width: 450px) {
  [data-modal-overlay] {
    max-width: 375px;
    margin: 0 auto;
  }
}


.cpanel{
  [data-modal-confirm] {
    max-width: calc(90%);
  }
}

@media screen and (max-width: 450px) {
  [data-modal-confirm] {
    max-width: calc(90%);
  }
}

[data-modal-popup] {
  &.qrcode{
    [data-modal-popup] {
      &:not(.tool-color){
        margin-top: 230px;
        height: calc(100% - 230px)
      }
    }
  }
}
